import { FaCartShopping, FaPerson, FaUser } from "react-icons/fa6";
import React, { useContext, useEffect, useState } from "react";
import { BiMenuAltLeft, BiMenuAltRight } from "react-icons/bi";
import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "./sidebar";
import { FaSignOutAlt } from "react-icons/fa";
import Header from "./header";
import { DataContext } from "../context/dataContext";

const Layout = () => {
  const [state, disaptch] = useContext(DataContext);
  const Navigate = useNavigate();

  useEffect(() => {
    // Navigate("login");
  }, []);

  return (
    <>
      <div className="w-full border border-b-1">
        <Header />
      </div>
      <div className="flex flex-row relative ">
        <div
          className={` ${
            state.openSideBar
              ? "w-1/4 opacity-100 max-md:w-full max-md:fixed max-md:inset-0 z-50"
              : "w-0 opacity-0"
          } transition-all overflow-hidden relative `}
        >
          <SideBar />
        </div>

        <div className="w-full overflow-x-hidden">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
