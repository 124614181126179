import React, { Fragment, useRef, useState } from "react";
import { FaEdit, FaHistory } from "react-icons/fa";
import { FaCamera, FaFilter, FaPlus } from "react-icons/fa6";
import { GrClear } from "react-icons/gr";
import { Dialog, Transition } from "@headlessui/react";

const Customers = () => {
  const customers = [
    {
      id: 1,
      name: "Mohamad Asaad",
      telephone: "76433787",
      token: "token",
      expired: "1h",
      dateAdded: "2024-07-01",
    },
    {
      id: 1,
      name: "Mohamad Asaad",
      telephone: "76433787",
      token: "token",
      expired: "1h",
      dateAdded: "2024-07-01",
    },
    {
      id: 1,
      name: "Mohamad Asaad",
      telephone: "76433787",
      token: "token",
      expired: "1h",
      dateAdded: "2024-07-01",
    },
    {
      id: 1,
      name: "Mohamad Asaad",
      telephone: "76433787",
      token: "token",
      expired: "1h",
      dateAdded: "2024-07-01",
    },
  ];
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="w-full  px-10 py-6 flex flex-col ">
        <div className="flex flex-row justify-between">
          <h2 className="text-2xl font-bold text-primary"> Customers</h2>
        </div>

        <div className="w-full py-5">
          <div class=" border border-gray-200 bg-white p-6 shadow-lg">
            <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              <div class="flex flex-col">
                <label for="id" class="text-stone-600 text-sm font-medium">
                  ID
                </label>
                <input
                  type="text"
                  id="id"
                  placeholder="raspberry juice"
                  class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>
              <div class="flex flex-col">
                <label for="name" class="text-stone-600 text-sm font-medium">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder="raspberry juice"
                  class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>

              <div class="flex flex-col">
                <label
                  for="date_added"
                  class="text-stone-600 text-sm font-medium"
                >
                  Date Added
                </label>
                <input
                  type="date"
                  id="date_added"
                  class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>
            </div>

            <div class="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
              <button class="active:scale-95  bg-gray-200 px-4 py-2  text-gray-600 outline-none focus:ring hover:opacity-90 flex flex-row gap-2 text-sm">
                <GrClear className="my-auto" />
                <span>Reset</span>
              </button>
              <button class="active:scale-95 text-sm  bg-primary px-4 py-2  text-white outline-none focus:ring hover:opacity-90 flex flex-row gap-2">
                <FaFilter className="my-auto" />
                <span>Filter</span>
              </button>
            </div>
          </div>
        </div>

        <div className=" table-products mt-4 shadow-md ">
          <table className="min-w-full  border-collapse divide-y divide-gray-200 ">
            <thead className="">
              <tr className="divide-x divide-gray-200 divide-y  ">
                <th
                  scope="col"
                  className="px-4 py-2 text-center  text-sm font-medium text-gray-900 uppercase bg-gray-50"
                >
                  ID
                </th>

                <th
                  scope="col"
                  className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                >
                  Name
                </th>

                <th
                  scope="col"
                  className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                >
                  User Group
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                >
                  Status
                </th>

                <th
                  scope="col"
                  className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                >
                  Date Added
                </th>
                <th
                  scope="col"
                  className="px-1 py-2 text-center text-sm font-medium text-gray-900  bg-gray-50"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer) => (
                <tr
                  key={customer.id}
                  className="hover:bg-gray-100 divide-x divide-y divide-gray-200"
                >
                  <td className="px-4 py-4 border-b text-sm font-normal text-center text-gray-700">
                    {customer.id}
                  </td>

                  <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                    {customer.name}
                  </td>

                  <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                    {customer.telephone}
                  </td>
                  <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                    {customer.expired}
                  </td>
                  <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                    {customer.dateAdded}
                  </td>

                  <td className="text-primary  px-2  ">
                    <div className="flex flex-row gap-2 justify-center">
                      <button>
                        <FaEdit className="my-auto mx-auto " />
                      </button>
                      <button>
                        <FaHistory className="my-auto mx-auto " />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Customers;
