export const DataReducer = (state, action) => {
  switch (action.type) {
    case "openSideBar":
      return {
        ...state,
        openSideBar: action.payload,
      };
    case "setCartData":
      return {
        ...state,
        cartData: action.payload,
      };

    default:
      return state;
  }
};

export const initialState = {
  openSideBar: true,
  cartData: null ,
};
