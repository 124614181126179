import React, { useContext, useEffect } from "react";
import { AiOutlineProduct } from "react-icons/ai";
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaCartShopping, FaPerson, FaUser, FaUserGroup } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { TbCategoryPlus } from "react-icons/tb";
import { DataContext } from "../context/dataContext";

const SideBar = () => {
  const location = useLocation();

  return (
    <div className="h-screen">
      <div className="flex flex-col bg-primary h-full  text-white gap-2 w-full absolute">
        <div className=" py-5 px-5 flex flex-row justify-start gap-4 border-b">
          <div className="rounded-full bg-light h-14 w-14 flex justify-center">
            <span className="my-auto text-xl font-bold">G</span>
          </div>
          <span className="text-lg  my-auto">Mohamad Asaad </span>
        </div>
        <Link
          to={"product"}
          className={`flex flex-row justify-between group hover:bg-light px-3 py-3 ${
            location.pathname.includes("product") && "bg-light"
          }`}
        >
          <div className="flex flex-row gap-2  ">
            <AiOutlineProduct className="my-auto text-xl" />
            <span>Products</span>
          </div>
          <FaAngleDoubleRight className="text-md my-auto opacity-70 group-hover:translate-x-2 transition-all  " />
        </Link>
        <Link
          to={"categories"}
          className={`flex flex-row justify-between group hover:bg-light px-3 py-3 ${
            location.pathname.includes("categories") && "bg-light"
          }`}
        >
          <div className="flex flex-row gap-2  ">
            <TbCategoryPlus className="my-auto text-xl" />
            <span>Categories</span>
          </div>
          <FaAngleDoubleRight className="text-md my-auto opacity-70 group-hover:translate-x-2 transition-all  " />
        </Link>
        <Link
          to={"populars"}
          className={`flex flex-row justify-between group hover:bg-light px-3 py-3 ${
            location.pathname.includes("populars") && "bg-light"
          }`}
        >
          <div className="flex flex-row gap-2  ">
            <AiOutlineProduct className="my-auto text-xl" />
            <span>Populars</span>
          </div>
          <FaAngleDoubleRight className="text-md my-auto opacity-70 group-hover:translate-x-2 transition-all  " />
        </Link>
        <Link
          to={"users"}
          className={`flex flex-row justify-between group hover:bg-light px-3 py-3 ${
            location.pathname.includes("users") && "bg-light"
          }`}
        >
          <div className="flex flex-row gap-2  ">
            <FaUser className="my-auto text-xl" />
            <span>Users</span>
          </div>
          <FaAngleDoubleRight className="text-md my-auto opacity-70 group-hover:translate-x-2 transition-all  " />
        </Link>
        <Link
          to={"userGroup"}
          className={`flex flex-row justify-between group hover:bg-light px-3 py-3 ${
            location.pathname.includes("userGroup") && "bg-light"
          }`}
        >
          <div className="flex flex-row gap-2  ">
            <FaUserGroup className="my-auto text-xl" />
            <span>Users Group</span>
          </div>
          <FaAngleDoubleRight className="text-md my-auto opacity-70 group-hover:translate-x-2 transition-all  " />
        </Link>
        <Link
          to={"customers"}
          className={`flex flex-row justify-between group hover:bg-light px-3 py-3 ${
            location.pathname.includes("customers") && "bg-light"
          }`}
        >
          <div className="flex flex-row gap-2  ">
            <FaPerson className="my-auto text-xl" />
            <span>Customers</span>
          </div>
          <FaAngleDoubleRight className="text-md my-auto opacity-70 group-hover:translate-x-2 transition-all  " />
        </Link>

        <Link
          to={""}
          className={`flex flex-row justify-between group hover:bg-light px-3 py-3 ${
            location.pathname.includes("sd") && "bg-light"
          }`}
        >
          <div className="flex flex-row gap-2  ">
            <FaCartShopping className="my-auto text-xl" />
            <span>Orders</span>
          </div>
          <FaAngleDoubleRight className="text-md my-auto opacity-70 group-hover:translate-x-2 transition-all  " />
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
