import React, { useContext, useEffect, useState } from "react";
import { FaList, FaTrash } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { DataContext } from "../context/dataContext";
import { public_url_file } from "../hooks/BuildLink";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const LayoutMenu = () => {
  const [toogleDropdown, setToogleDropdown] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [state, dispatch] = useContext(DataContext);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");
      if (window.scrollY > 90) {
        navbar.classList.add("layout-bg-color");
      } else {
        navbar.classList.remove("layout-bg-color");
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function updateCartData(product, plus) {
    const cartList = state.cartData ? [...state.cartData] : [];
    const productIndex = cartList.findIndex(
      (item) => item.product_id === product.product_id
    );

    if (productIndex > -1) {
      if (plus) {
        cartList[productIndex].quantity += 1;
      } else if (cartList[productIndex].quantity > 1) {
        cartList[productIndex].quantity -= 1;
      }
      const quantity = cartList[productIndex].quantity;
      dispatch({ type: "setCartData", payload: cartList });
    }
  }

  function removeProductFromCart(cart) {
    const cartList = state.cartData ? [...state.cartData] : [];
    const productIndex = cartList.findIndex(
      (item) => item.product_id === cart.product_id
    );

    if (productIndex > -1) {
      cartList.splice(productIndex, 1);
      dispatch({ type: "setCartData", payload: cartList });
    }
  }

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const checkout = () => {
    const date = getCurrentDate();

    var message = "";
    message += "Invoice  " + "\n";
    message += "Details : \n";
    state.cartData.forEach((item) => {
      message += `(${item.quantity}X ${item.name})\n`;
    });

    message +=
      "\n ==================== \n" +
      "Total : $" +
      state.cartData.reduce(
        (total, cart) => total + cart.quantity * cart.price,
        0
      ) +
      "\n";
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;
    dispatch({ type: "setCartData", payload: [] });

    window.open(whatsappUrl, "_blank");
  };

  return (
    <>
      <div
        id="navbar"
        className=" w-full flex flex-row justify-between px-44  py-5 fixed top-0 z-[999]  transition-all duration-300 max-md:px-2"
      >
        <div className="w-[20%] max-md:w-[30%]">
          <img
            src="https://test.softpro.me/test/mohamad/restaurantpage/assets/logo-dark.png"
            alt="logo"
          />
        </div>
        <div className="flex flex-row gap-6 my-auto font-mono max-md:hidden">
          <ScrollLink
            to="target-home"
            smooth={true}
            duration={500}
            className="cursor-pointer"
          >
            Home
          </ScrollLink>
          <ScrollLink
            to="target-special"
            smooth={true}
            duration={500}
            className="cursor-pointer"
          >
            Special
          </ScrollLink>
          <ScrollLink
            to="target-menu"
            smooth={true}
            duration={500}
            className="cursor-pointer"
          >
            Menu
          </ScrollLink>
          <ScrollLink
            to="target-footer"
            smooth={true}
            duration={500}
            className="cursor-pointer"
          >
            Contact Us
          </ScrollLink>
        </div>

        <div className=" flex flex-row my-auto gap-10 ">
          <button
            className="relative"
            onClick={() => {
              setOpenCart(!openCart);
            }}
          >
            {state.cartData && state.cartData.length > 0 && (
              <span className="  bg-red-800 absolute -top-4 -right-3 rounded-full w-5 h-5 text-white  ">
                <div className="my-auto text-sm">{state.cartData.length}</div>
              </span>
            )}

            <FaCartShopping className="text-lg" />
          </button>

          <button
            className="md:hidden my-auto"
            onClick={() => setToogleDropdown(!toogleDropdown)}
          >
            <FaList className="text-[#41200C]" />
          </button>
        </div>
      </div>

      {toogleDropdown && (
        <div className="fixed w-full h-[40%] z-[99] ">
          <div className="relative w-full h-full gap-4 bg-white flex flex-col pt-20 px-4">
            <ScrollLink
              to="target-home"
              smooth={true}
              duration={500}
              onClick={() => setToogleDropdown(false)}
              className="cursor-pointer"
            >
              Home
            </ScrollLink>
            <ScrollLink
              to="target-special"
              onClick={() => setToogleDropdown(false)}
              smooth={true}
              duration={500}
              className="cursor-pointer"
            >
              Special
            </ScrollLink>
            <ScrollLink
              to="target-menu"
              onClick={() => setToogleDropdown(false)}
              smooth={true}
              duration={500}
              className="cursor-pointer"
            >
              Menu
            </ScrollLink>
            <ScrollLink
              to="target-footer"
              onClick={() => setToogleDropdown(false)}
              smooth={true}
              duration={500}
              className="cursor-pointer"
            >
              Contact Us
            </ScrollLink>
          </div>
        </div>
      )}
      <Outlet />

      {openCart && (
        <>
          <div
            className="bg-black fixed inset-0 h-full w-full bg-opacity-30 z-[9999]"
            onClick={() => setOpenCart(false)}
          ></div>
          <div className=" z-[9999] fixed w-[30%] h-full bottom-0 max-md:h-[80%] md:top-0 bg-white right-0  border border-l border-[#41200C] max-md:w-full ">
            <div className="flex flex-col gap-7 h-full justify-between p-5">
              <div className=" flex flex-row justify-between">
                <h2 className="text-2xl  font-bold">
                  Cart{" "}
                  {state.cartData &&
                    state.cartData.length > 0 &&
                    "(" + state.cartData.length + " Products)"}
                </h2>
                <button
                  className="text-lg font-bold "
                  onClick={() => setOpenCart(false)}
                >
                  X
                </button>
              </div>

              <div className="flex flex-col flex-grow  gap-4 justify-start ">
                {state.cartData &&
                  state.cartData.map((cart) => (
                    <div className="flex flex-row justify-between shadow-lg py-2 px-2">
                      <div className="flex flex-row gap-2">
                        <div className=" w-28 h-20 overflow-hidden rounded-lg">
                          <img
                            src={public_url_file + cart.image}
                            className=" object-cover w-full h-full"
                            alt="rabie"
                          />
                        </div>
                        <div className="flex flex-col justify-between py-2">
                          <h2 className="font-bold font-mono">{cart.name}</h2>
                          <h2 className="font-bold">${cart.price}</h2>
                        </div>
                      </div>

                      <div className="flex flex-col justify-between">
                        <div className="flex flex-row   rounded-xl w-20 ">
                          <button
                            className="rounded-sm p-1 w-6 border border-sm hover:shadow-lg"
                            onClick={() => updateCartData(cart, false)}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            className=" text-center input-number w-8  border border-sm outline-none bg-transparent "
                            value={cart.quantity}
                            readOnly
                          />
                          <button
                            className=" rounded-sm  w-6 border border-sm hover:shadow-lg"
                            onClick={() => updateCartData(cart, true)}
                          >
                            +
                          </button>
                        </div>
                        <button
                          className="flex flex-row justify-end"
                          onClick={() => removeProductFromCart(cart)}
                        >
                          <FaTrash className=" text-red-500 hover:opacity-70" />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="flex flex-row justify-between">
                <h2 className=" my-auto text-3xl font-bold">
                  {" "}
                  Total: $
                  {state.cartData && state.cartData.length>0 && state.cartData.reduce(
                    (total, cart) => total + cart.quantity * cart.price,
                    0
                  )}
                </h2>
                <button
                  className="p-3 border rounded-full  bg-[#41200C] text-white"
                  onClick={checkout}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default LayoutMenu;
