import React, { useRef } from "react";
import { FaLock } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const formRef = useRef(null);
  const Navigate=useNavigate();

  const submitLogin = () => {
    Navigate('/admin')
  };
  return (
    <div className="w-full h-screen flex flex-col justify-center bg-gray-100">
      <form
        className="flex flex-col gap-12 text-center border-2 px-6 py-6 w-[60%] mx-auto justify-center rounded-3xl h-[50%] bg-white"
        ref={formRef}
      >
        <h2 className="font-bold text-4xl">Log In </h2>
        <div className="flex flex-col gap-6 w-full px-64">
          <div className="flex flex-row gap-4 mx-auto w-full">
            <FaUser className="my-auto" />
            <input
              className="border py-1 px-2 rounded-md w-full"
              type="text"
              name="username"
              placeholder="Enter Your Username"
            />
          </div>
          <div className="flex flex-row gap-4 mx-auto w-full">
            <FaLock className="my-auto" />
            <input
              className="border py-1 px-2 rounded-md  w-full"
              type="password"
              name="password"
              placeholder="Enter Your Password"
            />
          </div>
          <div className="h-full w-full pl-8 text-white">
            <button
              className="bg-gray-600 mx-auto w-full py-2 rounded-md hover:opacity-70 "
              onClick={submitLogin}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
