import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  FaMailBulk,
  FaWhatsapp,
} from "react-icons/fa";
import { FaLocationPin } from "react-icons/fa6";

const Footer = () => {
  return (
    <div
      className="flex flex-col h-[400px] max-md:h-full px-10  text-white"
      style={{
        backgroundImage:
          "url(https://test.softpro.me/test/mohamad/restaurantpage/assets/footer-bg.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-row max-md:flex-col h-full justify-between gap-4 py-10   ">
        <div className="flex flex-col gap-4 h-full justify-center max-md:w-full w-[50%]">
          <div className="w-[20%] max-md:w-[50%]">
            <img
              className="h-full w-full object-cover"
              src="https://test.softpro.me/test/mohamad/restaurantpage/assets/logo-white.png"
              alt="logo"
            />
          </div>
          <p className=" text-white opacity-50 text-sm">
            Welcome to Burger Company, where passion for exceptional food and
            genuine hospitality come together. Our story is one of dedication to
            crafting the perfect burger experience, from sourcing the finest
            ingredients to delivering unparalleled taste in every bite.
          </p>
        </div>
        <div className="flex flex-col  gap-6 justify-center text-right  w-[50%] max-md:w-full ">
          <div className="flex flex-row justify-end gap-2 max-md:justify-start">
            <FaLocationPin className="my-auto" />
            <span>Syr Al Danniyeh</span>
          </div>
          <div className="flex flex-row justify-end gap-2 max-md:justify-start">
            <FaMailBulk className="my-auto" />
            <span>test.123@gmail.com</span>
          </div>
          <div className="flex flex-row  justify-end gap-6  text-xl max-md:justify-start">
            <FaFacebook className="my-auto" />
            <FaInstagram className="my-auto" />
            <FaWhatsapp className="my-auto" />
          </div>
        </div>
      </div>
      <p className="pt-4 text-center">Copyright © 2024 burgerhouse. All rights reserved.</p>
    </div>
  );
};

export default Footer;
