import React, { useEffect, useState } from "react";
import LayoutMenu from "../components/LayoutMenu";
import Menu from "../components/menu";
import product_test from "../assets/images/product_test.jpeg";
import { FaAngleDown, FaArrowDown } from "react-icons/fa";
import { Link } from "react-scroll";
import Footer from "../components/Footer";
import { buildLink, public_url_file } from "../hooks/BuildLink";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";
const Home = () => {
  const [category, setCategory] = useState(null);
  const [products, setProducts] = useState(null);
  const [popularsProducts, setPopularsProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    Aos.init();
  }, []);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    const result = await Promise.all([
      axios.get(buildLink("categories")+"?web_menu"),
      axios.get(buildLink("products_sub_category")),
      axios.get(buildLink("populars_products") + "?limit=true"),
    ]);

    // Handle the results
    setCategory(result[0].data);
    setProducts(result[1].data);
    setPopularsProducts(result[2].data);
    if (result[0].data && result[1].data && result[2].data) setLoading(false);
  }

  return (
    <>
      {loading ? (
        <div className=" w-full h-screen flex justify-center">
          <svg
            className=" my-auto animate-spin h-8 w-8 text-[#41200C]"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v4l3.5-3.5L12 0v4a8 8 0 000 16v-4l-3.5 3.5L12 24v-4a8 8 0 01-8-8z"
            ></path>
          </svg>
        </div>
      ) : (
        <div className="w-full h-full">
          <div
            className="pt-28 h-full bg-yellow-500" id="target-home"
           
          >
            <div className="flex flex-row  max-md:flex-col">
              <div className="flex px-44  max-md:px-3 flex-row">
                <div
                  className=" w-[70%] flex flex-col my-auto gap-4"
                 
                >
                  <h2 className=" border-2 p-3 border-dashed font-mono font-semibold border-[#41200C] text-[#41200C] text-xl text-center">
                    IT IS GOOD TIME FOR THE GREATE TASTE OF BURGER
                  </h2>
                  <h1
                    className="text-7xl font-extrabold alfa-slab-one-regular text-[#41200C] "
                    style={{ textShadow: "2px 2px 4px #FFFFFF" }}
                  >
                    BURGER WEEK
                  </h1>
                </div>
              </div>

              <div >
                <img
                  src="https://test.softpro.me/test/mohamad/restaurantpage/assets/header.png"
                  alt=""
                />
              </div>
            </div>

            <div className=" w-full  py-10 ">
              <Link
                to="target-menu"
                smooth={true}
                duration={500}
                className="cursor-pointer"
              >
                <div className="mx-auto w-fit flex animate-bounce px-4 rounded-lg flex-row gap-2">
                  <span className="font-thin drop-shadow-md">SHOW MENU</span>
                  <FaAngleDown className="my-auto" />
                </div>
              </Link>
            </div>
          </div>

          {popularsProducts &&
            popularsProducts.length > 0 &&
            popularsProducts[0].image && (
              <div className=" h-[500px] my-10" id="target-special">
                <div className="grid grid-cols-2 gap-4 relative px-28 max-md:px-2 max-md:grid-cols-1  h-full max-md:h-full " >
                  <div
                   
                    className=" relative overlay overflow-hidden rounded-xl h-full border-2  "
                    style={{
                      backgroundImage: `url(${public_url_file}${popularsProducts[0].image})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="absolute z-50 text-white font-mono font-semibold  p-3 my-center">
                      <h2 className="text-xl ">TRY IT OUT TODAY</h2>
                      <h2 className="text-3xl">{popularsProducts[0].name}</h2>
                    </div>
                  </div>

                  <div className="flex-col flex h-full gap-2 ">
                    <div
                    
                      className="relative  overlay bg-red-500  h-full border-2  overflow-hidden rounded-xl "
                      style={{
                        backgroundImage: `url(${public_url_file}${popularsProducts[1].image})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="absolute z-50 text-white font-mono font-semibold  p-3 my-center">
                        <h2 className="text-xl max-md:text-lg ">
                          TRY IT OUT TODAY
                        </h2>

                        <h2 className="text-3xl max-md:text-xl">
                          {popularsProducts[1].name}
                        </h2>
                      </div>
                    </div>

                    <div
                     
                      className=" h-full  relative overlay border-2 overflow-hidden rounded-xl"
                      style={{
                        backgroundImage: `url(${public_url_file}${popularsProducts[2].image})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="absolute z-50 text-white font-mono font-semibold  p-3 my-center">
                        <h2 className="text-xl  max-md:text-lg">
                          TRY IT OUT TODAY
                        </h2>

                        <h2 className="text-3xl  max-md:text-xl">
                          {popularsProducts[2].name}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div className=" py-5 " id="target-menu">
            <Menu products={products} category={category} />
          </div>

          <div className="w-full h-full" id="target-footer">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
