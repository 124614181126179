import React, { useContext, useEffect } from "react";
import { BiMenu, BiMenuAltLeft, BiMenuAltRight } from "react-icons/bi";
import { FaSignOutAlt } from "react-icons/fa";
import logo_company from "../assets/images/logo_company.png";
import { DataContext } from "../context/dataContext";

const Header = () => {
  const [state, disaptch] = useContext(DataContext);

  return (
    <div className="flex flex-row justify-between  h-[50px] px-3">
      <div className="flex flex-row gap-5 my-auto h-full">
        <button
          className="border-r h-full px-2"
          onClick={() =>
            disaptch({ type: "openSideBar", payload: !state.openSideBar })
          }
        >
            {state.openSideBar ? <BiMenuAltLeft className="text-3xl text-primary" />:<BiMenu className="text-3xl text-primary" />   }
          
        </button>
        <div className="w-28 my-auto">
          <img src={logo_company} alt="" />
        </div>
      </div>
      <div className="flex flex-row my-auto   border-l px-auto h-full ">
        <button className="flex flex-row gap-1 text-primary text-sm my-auto px-2 hover:opacity-70 ">
          <span>Logout</span>

          <FaSignOutAlt className="my-auto" />
        </button>
      </div>
    </div>
  );
};

export default Header;
