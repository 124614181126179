import { createContext, useReducer } from "react";
import { DataReducer, initialState } from "../reducers/dataReducer";

export const DataContext = createContext({
  state: initialState,
  dispatch: () => null,
});

export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DataReducer, initialState);

  return (
    <DataContext.Provider value={[state, dispatch]}>
      {children}
    </DataContext.Provider>
  );
};


