import React, { Fragment, useEffect, useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { FaFilter, FaPlus } from "react-icons/fa6";
import { GrClear } from "react-icons/gr";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { buildLink } from "../hooks/BuildLink";

const Users = () => {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    try {
      // Initiate both requests concurrently
      const result = await axios.get(buildLink("users"));
      // Handle the results of both requests
      console.log(result);
      setUsers(result.data);
      setLoading(false);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  }

  return (
    <>
      {loading ? (
        <div className=" w-full h-full flex justify-center">
          <svg
            className=" my-auto animate-spin h-8 w-8 text-secondary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v4l3.5-3.5L12 0v4a8 8 0 000 16v-4l-3.5 3.5L12 24v-4a8 8 0 01-8-8z"
            ></path>
          </svg>
        </div>
      ) : (
        <div className="w-full  px-10 py-6 flex flex-col ">
          <div className="flex flex-row justify-between">
            <h2 className="text-2xl font-bold text-primary"> Users</h2>
            <button
              className="flex flex-row gap-1 bg-secondary py-2 text-sm text-white px-2 rounded-md hover:opacity-65"
              onClick={() => setOpen(true)}
            >
              <FaPlus className="my-auto" />
              <span className="my-auto">NEW</span>
            </button>
          </div>

          <div className="w-full py-5">
            <div class=" border border-gray-200 bg-white p-6 shadow-lg">
              <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="flex flex-col">
                  <label for="id" class="text-stone-600 text-sm font-medium">
                    ID
                  </label>
                  <input
                    type="text"
                    id="id"
                    placeholder="raspberry juice"
                    class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="name" class="text-stone-600 text-sm font-medium">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="raspberry juice"
                    class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>

                <div class="flex flex-col">
                  <label
                    for="status"
                    class="text-stone-600 text-sm font-medium"
                  >
                    User Group
                  </label>

                  <select
                    id="status"
                    class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  >
                    <option>--none--</option>
                    <option>Admin</option>
                    <option>Supervisor</option>
                  </select>
                </div>

                <div class="flex flex-col">
                  <label
                    for="status"
                    class="text-stone-600 text-sm font-medium"
                  >
                    Status
                  </label>

                  <select
                    id="status"
                    class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  >
                    <option>--none--</option>
                    <option>Enable</option>
                    <option>Disable</option>
                  </select>
                </div>

                <div class="flex flex-col">
                  <label
                    for="date_added"
                    class="text-stone-600 text-sm font-medium"
                  >
                    Date Added
                  </label>
                  <input
                    type="date"
                    id="date_added"
                    class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
              </div>

              <div class="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
                <button class="active:scale-95  bg-gray-200 px-4 py-2  text-gray-600 outline-none focus:ring hover:opacity-90 flex flex-row gap-2 text-sm">
                  <GrClear className="my-auto" />
                  <span>Reset</span>
                </button>
                <button class="active:scale-95 text-sm  bg-primary px-4 py-2  text-white outline-none focus:ring hover:opacity-90 flex flex-row gap-2">
                  <FaFilter className="my-auto" />
                  <span>Filter</span>
                </button>
              </div>
            </div>
          </div>

          <div className=" table-products mt-4 shadow-md ">
            <table className="min-w-full  border-collapse divide-y divide-gray-200 ">
              <thead className="">
                <tr className="divide-x divide-gray-200 divide-y  ">
                  <th
                    scope="col"
                    className="px-4 py-2 text-center  text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    ID
                  </th>

                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    User Group
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    Status
                  </th>

                  <th
                    scope="col"
                    className="px-1 py-2 text-center text-sm font-medium text-gray-900  bg-gray-50"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.map((user) => (
                    <tr
                      key={user.id}
                      className="hover:bg-gray-100 divide-x divide-y divide-gray-200"
                    >
                      <td className="px-4 py-4 border-b text-sm font-normal text-center text-gray-700">
                        {user.id}
                      </td>

                      <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                        {user.name}
                      </td>

                      <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                        {user.user_group_name}
                      </td>

                      <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                        {user.status ? "Enabled" : "Disabled"}
                      </td>

                      <td className="text-primary  px-2  ">
                        <div className="flex flex-row gap-2 justify-center">
                          <button>
                            <FaEdit className="my-auto mx-auto " />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {open && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <form className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:w-[70%]  w-[95%]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="flex flex-col gap-4">
                        <h2 className="text-xl font-medium ">Add New User</h2>
                        <hr />

                        <div className="flex flex-row w-full gap-4">
                          <div class="flex flex-col w-full">
                            <label
                              for="name"
                              class="text-light text-sm font-bold "
                            >
                              Name:
                            </label>
                            <input
                              type="text"
                              id="name"
                              placeholder="name"
                              class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            />
                          </div>

                          <div class="flex flex-col w-full ">
                            <label
                              for="name"
                              class="text-light text-sm font-bold "
                            >
                              User Group:
                            </label>
                            <select
                              id="status"
                              class="mt-2 block w-full  border border-gray-200 px-2 py-[0.30rem] rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            >
                              <option>Admin</option>
                              <option>Supervisor</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex flex-row w-full gap-4">
                          <div class="flex flex-col w-full">
                            <label
                              for="name"
                              class="text-light text-sm font-bold "
                            >
                              Password:
                            </label>
                            <input
                              type="password"
                              id="name"
                              placeholder="name"
                              class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            />
                          </div>
                          <div class="flex flex-col w-full">
                            <label
                              for="name"
                              class="text-light text-sm font-bold "
                            >
                              Confirm Password:
                            </label>
                            <input
                              type="password"
                              id="name"
                              placeholder="name"
                              class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            />
                          </div>
                        </div>
                        <div className="flex flex-row w-full gap-4">
                          <div class="flex flex-col  w-[50%] ">
                            <label
                              for="name"
                              class="text-light text-sm font-bold "
                            >
                              Status:
                            </label>
                            <select
                              id="status"
                              class="mt-2 block w-full  border border-gray-200 px-2 py-[0.30rem] rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            >
                              <option value={1}>Enable</option>
                              <option value={0}>Disable</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 gap-2 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-secondary text-white px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:opacity-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </form>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};

export default Users;
