import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { FaCamera, FaFilter, FaPlus, FaTrash } from "react-icons/fa6";
import { GrClear } from "react-icons/gr";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { buildLink, public_url_file } from "../hooks/BuildLink";
import { FaEdit, FaExclamationTriangle, FaHistory } from "react-icons/fa";
import { Dialog, Transition } from "@headlessui/react";

const Populars = () => {
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();
  const formFilterRef = useRef(null);
  const [products, setProducts] = useState(null);
  const location = useLocation();
  const [filters, setFilters] = useState(location.search);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const formRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const cancelButtonDeleteRef = useRef(null);
  const [DeletedProductId, setDeletedProductId] = useState(null);

  function HandleDeletePopularProduct(id) {
    setOpenDelete(true);
    setDeletedProductId(id);
  }
  useEffect(() => {
    getPopularsProducts();
  }, [filters]);

  function filterProduct(e) {
    console.log("hello");
    e.preventDefault();
    const formData = new FormData(formFilterRef.current);
    let filters = [];
    for (const [key, value] of formData.entries()) {
      if (value && value != -1) filters.push(`${key}=${value}`);
    }
    const queryString = filters.join("&");
    Navigate(`?${queryString}`);
    setFilters(`?${queryString}`);
  }

  const addPopularProduct = async (e) => {
    setLoadingDialog(true);
    e.preventDefault();
    // Create FormData from the form element
    const formData = new FormData(formRef.current);

    if (productToEdit && productToEdit.id) {
      formData.append("id", productToEdit.id);
    }

    try {
      const response = await axios.post(buildLink("add_popular_product"), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Product added:", response.data);
      getPopularsProducts();
      setLoadingDialog(false);

      setOpen(false);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const getPopularsProducts = async () => {
    setLoading(true);
    let temp = "";

    if (filters) {
      temp = filters;
    }

    const reponse = await axios.get(buildLink("populars_products") + temp);
    setProducts(reponse.data);
    setLoading(false);
  };

  function handleClickEditPopularProduct(product) {
    setProductToEdit(product);
    setOpen(true);
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function handleClickNewPopularProduct() {
    setProductToEdit(null);
    setOpen(true);
  }

  async function DeletePopularProduct() {
    setLoadingDialog(true);
    const obj = {
      id: DeletedProductId,
    };
    try {
      const response = await axios.delete(buildLink("delete_popular_product"), {
        data: obj,
      });

      console.log("Product deleted:", response.data);
      getPopularsProducts();
      setLoadingDialog(false);
      setOpenDelete(false);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  }

  return (
    <>
      {loading ? (
        <div className=" w-full h-full flex justify-center">
          <svg
            className=" my-auto animate-spin h-8 w-8 text-secondary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v4l3.5-3.5L12 0v4a8 8 0 000 16v-4l-3.5 3.5L12 24v-4a8 8 0 01-8-8z"
            ></path>
          </svg>
        </div>
      ) : (
        <div className="w-full  px-10 py-6 flex flex-col ">
          <div className="flex flex-row justify-between">
            <h2 className="text-2xl font-bold text-primary">
              {" "}
              Populars Products
            </h2>
            <button
              className="flex flex-row gap-1 bg-secondary py-2 text-sm text-white px-2 rounded-md hover:opacity-65"
              onClick={() => handleClickNewPopularProduct()}
            >
              <FaPlus className="my-auto" />
              <span className="my-auto">NEW</span>
            </button>
          </div>

          <div className="w-full py-5">
            <form
              class=" border border-gray-200 bg-white p-6 shadow-lg"
              ref={formFilterRef}
              onSubmit={(e) => filterProduct(e)}
            >
              <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <div class="flex flex-col">
                  <label for="id" class="text-stone-600 text-sm font-medium">
                    ID
                  </label>
                  <input
                    type="text"
                    name="id"
                    id="id"
                    placeholder="raspberry juice"
                    class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="name" class="text-stone-600 text-sm font-medium">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="raspberry juice"
                    class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="name" class="text-stone-600 text-sm font-medium">
                    Description
                  </label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    placeholder="raspberry juice"
                    class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>

                <div class="flex flex-col">
                  <label
                    for="status"
                    class="text-stone-600 text-sm font-medium"
                  >
                    Status
                  </label>

                  <select
                    id="status"
                    name="status"
                    class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  >
                    <option value={-1}>--none--</option>
                    <option value={1}>Enable</option>
                    <option value={0}>Disable</option>
                  </select>
                </div>

                <div class="flex flex-col">
                  <label
                    for="date_added"
                    class="text-stone-600 text-sm font-medium"
                  >
                    Date Added
                  </label>
                  <input
                    type="date"
                    name="date_added"
                    id="date_added"
                    class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
              </div>

              <div class="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
                <Link
                  to={""}
                  class="active:scale-95  bg-gray-200 px-4 py-2  text-gray-600 outline-none focus:ring hover:opacity-90 flex flex-row gap-2 text-sm"
                >
                  <GrClear className="my-auto" />
                  <span>Reset</span>
                </Link>
                <button
                  class="active:scale-95 text-sm  bg-primary px-4 py-2  text-white outline-none focus:ring hover:opacity-90 flex flex-row gap-2"
                  type="submit"
                >
                  <FaFilter className="my-auto" />
                  <span>Filter</span>
                </button>
              </div>
            </form>
          </div>

          <div className=" table-products mt-4 shadow-md ">
            <table className="min-w-full  border-collapse divide-y divide-gray-200 ">
              <thead className="">
                <tr className="divide-x divide-gray-200 divide-y  ">
                  <th
                    scope="col"
                    className="px-4 py-2 text-center  text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    ID
                  </th>

                  <th
                    scope="col"
                    className="py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    Image
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    Sort Order
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-center text-sm font-medium text-gray-900 uppercase bg-gray-50"
                  >
                    Date Added
                  </th>
                  <th
                    scope="col"
                    className="px-1 py-2 text-center text-sm font-medium text-gray-900  bg-gray-50"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {products &&
                  products.map((product) => (
                    <tr
                      key={product.id}
                      className="hover:bg-gray-100 divide-x divide-y divide-gray-200"
                    >
                      <td className="px-4 py-4 border-b text-sm font-normal text-center text-gray-700">
                        {product.id}
                      </td>

                      <td className=" text-sm font-normal text-center  text-gray-700  w-32 px-2 ">
                        <div className="flex justify-center    rounded-md border border-light border-opacity-45 my-2">
                          <img
                            src={`${public_url_file}${product.image}`}
                            alt=""
                            className="h-full w-full object-cover  "
                          />
                        </div>
                      </td>
                      <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                        {product.name}
                      </td>

                      <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                        {product.price}
                      </td>
                      <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                        {product.description}
                      </td>
                      <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                        {product.sort_order}
                      </td>
                      <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                        {product.status === 0 ? "Enabled" : "Disabled"}
                      </td>
                      <td className="px-4 py-4 text-sm font-normal text-center text-gray-700">
                        {product.date_added}
                      </td>
                      <td className="text-primary  px-2  ">
                        <div className="flex flex-row gap-2 justify-center">
                          <button                             onClick={() => handleClickEditPopularProduct(product)}
                          >
                            <FaEdit className="my-auto mx-auto " />
                          </button>

                          <button
                            onClick={() =>
                              HandleDeletePopularProduct(product.id)
                            }
                          >
                            <FaTrash className="my-auto mx-auto  text-red-500 hover:opacity-50" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <form
              className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
              onSubmit={addPopularProduct}
              ref={formRef}
            >
              {loadingDialog && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
                  <svg
                    className="animate-spin h-8 w-8 text-secondary"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v4l3.5-3.5L12 0v4a8 8 0 000 16v-4l-3.5 3.5L12 24v-4a8 8 0 01-8-8z"
                    ></path>
                  </svg>
                </div>
              )}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:w-[70%]  w-[95%]">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="flex flex-col gap-4">
                      <h2 className="text-xl font-medium ">
                        {productToEdit && productToEdit.id
                          ? "Edit Product ID:(" + productToEdit.id + ")"
                          : "Add New Product"}
                      </h2>
                      <hr />

                      <div className="flex flex-row w-full gap-4">
                        <div class="flex flex-col w-full">
                          <label
                            for="name"
                            class="text-light text-sm font-bold "
                          >
                            Name:
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="name"
                            defaultValue={
                              productToEdit && productToEdit.id
                                ? productToEdit && productToEdit.name
                                : ""
                            }
                            class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          />
                        </div>

                        <div className="flex flex-col gap-4 justify-start w-full">
                          <div></div>
                          <label
                            htmlFor="file-input"
                            className="text-light my-auto border-dashed border w-full rounded-md shadow-sm py-[6px] mt-3 text-sm font-bold flex items-center cursor-pointer mx-auto"
                          >
                            {selectedImage ? (
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="mt-4 mx-auto max-w-full h-auto"
                              />
                            ) : productToEdit && productToEdit.id ? (
                              <img
                                src={`${public_url_file}${productToEdit.image}`}
                                alt="Selected"
                                className="mt-4 mx-auto max-w-full h-auto"
                              />
                            ) : (
                              <div className="mx-auto flex">
                                <FaCamera className="text-xl" />
                                <span className="ml-2">Upload Image</span>
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            id="file-input"
                            name="image"
                            className="hidden"
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>

                      <div className="flex flex-row w-full gap-4">
                        <div class="flex flex-col w-full ">
                          <label
                            for="name"
                            class="text-light text-sm font-bold "
                          >
                            Status:
                          </label>
                          <select
                            name="status"
                            defaultValue={
                              productToEdit && productToEdit.id
                                ? productToEdit && productToEdit.status
                                : ""
                            }
                            id="status"
                            class="mt-2 block w-full  border border-gray-200 px-2 py-[0.30rem] rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          >
                            <option value={-1}>--none--</option>
                            <option value={1}>Enable</option>
                            <option value={0}>Disable</option>
                          </select>
                        </div>

                        <div class="flex flex-col  w-full">
                          <label
                            for="name"
                            class="text-light text-sm font-bold "
                          >
                            Price:
                          </label>
                          <input
                            type="text"
                            id="name"
                            defaultValue={
                              productToEdit && productToEdit.id
                                ? productToEdit && productToEdit.price
                                : ""
                            }
                            name="price"
                            placeholder="name"
                            class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          />
                        </div>
                      </div>

                      <div className="flex flex-row w-full gap-4"></div>
                      <div className="flex flex-row w-full gap-4">
                        <div class="flex flex-col  w-full">
                          <label
                            for="name"
                            class="text-light text-sm font-bold "
                          >
                            Description:
                          </label>
                          <textarea
                            name="description"
                            defaultValue={
                              productToEdit && productToEdit.id
                                ? productToEdit && productToEdit.description
                                : ""
                            }
                            className="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          ></textarea>
                        </div>

                        <div class="flex flex-col  w-full">
                          <label
                            for="name"
                            class="text-light text-sm font-bold "
                          >
                            Sort Order:
                          </label>
                          <input
                            type="text"
                            id="name"
                            defaultValue={
                              productToEdit && productToEdit.id
                                ? productToEdit && productToEdit.sort_order
                                : ""
                            }
                            name="sort_order"
                            placeholder="Sort Order"
                            class="mt-2 block w-full  border border-gray-200 px-2 py-1 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 gap-2 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-secondary text-white px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:opacity-50 sm:mt-0 sm:w-auto"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </form>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openDelete} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonDeleteRef}
          onClose={setOpenDelete}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <FaExclamationTriangle
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Deleting product
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to this product? You will not
                            be able to get it back.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        DeletePopularProduct();
                      }}
                    >
                      confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpenDelete(false)}
                      ref={cancelButtonDeleteRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Populars;
