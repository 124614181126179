import React, { useContext, useEffect, useState } from "react";
import product_test from "../assets/images/product_test.jpeg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FaShoppingCart } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import axios from "axios";
import { buildLink, public_url_file } from "../hooks/BuildLink";
import Aos from "aos";
import "aos/dist/aos.css";
import { DataContext } from "../context/dataContext";
const Menu = ({ products, category }) => {
  const [state, dispatch] = useContext(DataContext);

  useEffect(() => {
    console.log(state.cartData);
  }, [state.cartData]);

  const addUpdateCart = (product) => {
    const cartList = state.cartData ? [...state.cartData] : [];

    const productIndex = cartList.findIndex(
      (item) => item.product_id === product.product_id
    );
    if (productIndex > -1) {
      cartList[productIndex].quantity += 1;
    } else {
      const addProduct = {
        product_id: product.product_id,
        price: parseFloat(product.price),
        quantity: 1,
        image: product.image,
        name: product.name,
      };
      cartList.push(addProduct);
    }

    dispatch({ type: "setCartData", payload: cartList });
  };

  useEffect(() => {
    Aos.init();
  }, []);

  const [filtredProducts, setFiltredProducts] = useState(
    products.filter((product) => {
      return product.parent === category[0].category_id;
    })
  );

  return (
    <div className="w-full h-full px-36 max-md:px-2 ">
      <div className="flex flex-col justify-center text-center gap-4 ">
        <h2
          className="text-4xl font-extrabold alfa-slab-one-regular text-[#41200C]"
          style={{ textShadow: "2px 2px 4px rgb(235,179,5)" }}
        >
          CHOOSE & ENJOY
        </h2>
        <p className="font-mono opacity-75">
          Whether you crave classic flavors or daring combinations, this is
          where your
        </p>
      </div>

      <div className="flex flex-row h-full mt-4   relative overflow-hidden mx-2">
        <Swiper
          onSlideChange={(swiper) => {
            const currentIndex = swiper.activeIndex;
            const currentCategory = category[currentIndex];
            console.log("Current Category ID:", currentCategory?.category_id);
            setFiltredProducts(
              products.filter((product) => {
                return product.parent === currentCategory.category_id;
              })
            );
          }}
          slidesPerView={2}
          spaceBetween={30}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper "
        >
          {category &&
            category.map((cat, index) => (
              <SwiperSlide key={index}>
                <div className="flex flex-col text-center justify-center gap-2">
                  <div className="rounded-full overflow-hidden w-28 h-28 mx-auto">
                    <img src={`${public_url_file}${cat.image}`} alt="kharif" />
                  </div>
                  <p>{cat.name}</p>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>

      {filtredProducts &&
        filtredProducts.map((data) => (
          <div key={data.id}>
            {!data.is_parent && (
              <h2
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
                className=" text-center py-2 my-3 border border-dashed bg-[#41200C] text-white rounded-lg text-3xl alfa-slab-one-regular"
              >
                {data.title}
              </h2>
            )}

            <div className="grid grid-cols-2 max-md:grid-cols-1 mt-5 gap-7">
              {data.items &&
                data.items.map((product) => (
                  <div
                    key={product.id}
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                    className="flex flex-row border-opacity-50 border rounded-xl overflow-hidden gap-4 shadow-xl shadow-gray-200"
                  >
                    <div className="w-[20%] max-md:w-[30%] h-32">
                      <img
                        className="w-full h-full object-cover"
                        src={`${public_url_file}${product.image}`}
                        alt={product.name}
                      />
                    </div>
                    <div className="flex flex-col py-4 justify-between w-[65%] max-md:w-[30%]">
                      <h2 className="text-xl font-bold font-mono">
                        {product.name}
                      </h2>
                      <p className="text-sm opacity-70 line-clamp-2">
                        {product.description}
                      </p>
                    </div>
                    <div className="flex flex-col py-4 justify-between mx-auto w-[5%]">
                      <div className="font-extrabold text-[#41200C]">
                        ${product.price}
                      </div>
                      <button
                        className="bg-red-500 rounded-full text-white p-2 mx-auto "
                        onClick={() => addUpdateCart(product)}
                      >
                        <FaPlus />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Menu;
