import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Catgeries from "./pages/Catgeries";
import { DataProvider } from "./context/dataContext";
import Products from "./pages/products";
import Users from "./pages/Users";
import Customers from "./pages/customers";
import UserGroup from "./pages/userGrup";
import Home from "./pages/Home";
import LayoutMenu from "./components/LayoutMenu";
import Login from "./pages/Login";
import Populars from "./pages/Populars";

function App() {
  return (
    <DataProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin" element={<Layout />}>
            <Route path="/admin/product" element={<Products />} />
            <Route path="/admin/categories" element={<Catgeries />} />
            <Route path="/admin/populars" element={<Populars />} />
            <Route path="/admin/users" element={<Users />} />
            <Route path="/admin/userGroup" element={<UserGroup />} />
            <Route path="/admin/customers" element={<Customers />} />
          </Route>

          <Route path="/" element={<LayoutMenu />}>
            <Route index element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </DataProvider>
  );
}

export default App;
